import httpClient from "./httpClient";

const PROJECT = "project";
const EXPERT = "expert";

const getPaginatedExperts = (offset, limit) =>
  httpClient.get(
    `${EXPERT}/get-paginated-experts?limit=${limit}&offset=${offset}`
  );

const getPaginatedProjects = (offset, limit) =>
  httpClient.get(
    `${PROJECT}/get-paginated-founder-project?limit=${limit}&offset=${offset}`
  );

export { getPaginatedProjects, getPaginatedExperts };

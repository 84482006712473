<template>
  <ContentCard class="admin-experts" v-loading="loading">
    <template #header>
      <div>
        <h2 class="primary-text">Experts</h2>
      </div>
    </template>
    <template #body>
      <el-table :data="experts">
        <el-table-column label="Name">
          <template #default="scope">{{ scope.row.user.name }}</template>
        </el-table-column>
        <el-table-column prop="companyName" label="Company Name" />
        <el-table-column label="Job">
          <template #default="scope">
            {{ scope.row.seniorityLevel ? scope.row.seniorityLevel.name : "" }}
          </template>
        </el-table-column>
        <el-table-column label="Skills">
          <template #default="scope">
            <span
              v-for="(item, index) in scope.row.expertHasSkills"
              :key="index"
              class="badge badge-secondary fs-6"
              style="margin-right: 0.5rem; margin-top: 0.5rem"
              >{{ item.skills.name }}</span
            ></template
          >
        </el-table-column>
        <el-table-column label="Action">
          <template #default="scope">
            <el-button
              @click="viewExpertProfileDrawer(scope.row.id)"
              class="btn btn-primary btn-sm mt-2"
              size="mini"
              >View</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-5"
        v-model:currentPage="offset"
        v-model:page-size="limit"
        :page-sizes="[5, 10, 50, 100]"
        small
        layout="total, sizes, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
  </ContentCard>
  <ExpertProfileDrawer ref="expertProfileDrawer" />
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import { onMounted, ref } from "vue";
import { getPaginatedExperts } from "../../api/admin.api";
import ExpertProfileDrawer from "../../expert/components/ExpertProfileDrawer";
export default {
  name: "Experts",
  components: { ExpertProfileDrawer, ContentCard },
  setup() {
    const experts = ref([]);
    const loading = ref(false);
    const total = ref(0);
    const offset = ref(1);
    const limit = ref(10);
    const expertProfileDrawer = ref(null);

    const getExperts = async (offset, limit) => {
      loading.value = true;
      const {
        data: { data },
      } = await getPaginatedExperts(offset - 1, limit);
      experts.value = data.results;
      total.value = data.total;
      loading.value = false;
    };

    const viewExpertProfileDrawer = (id) => {
      expertProfileDrawer.value.showDrawer(id);
    };

    const handleCurrentChange = (value) => {
      offset.value = value;
      getExperts(offset.value, limit.value);
    };

    const handleSizeChange = (value) => {
      offset.value = 1;
      limit.value = value;
      getExperts(offset.value, limit.value);
    };

    onMounted(() => {
      getExperts(offset.value, limit.value);
    });
    return {
      expertProfileDrawer,
      experts,
      offset,
      limit,
      total,
      loading,
      handleCurrentChange,
      viewExpertProfileDrawer,
      handleSizeChange,
    };
  },
};
</script>

<style scoped></style>
